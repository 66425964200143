import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getFormattedMoney, getOutcome, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import frameImg from './assets/frame.png';
import lockImg from './assets/Lock.png';
import multipliersLockImg from './assets/multipliers-lock.png';
import config from './dualFusionConfig';
import type { IDualFusionFeatures } from './dualFusionTypes';

interface IProps {
  data: IBet<IDualFusionFeatures>;
}

interface ICascadeStep {
  outcome: string[][];
  paylines: IPayline[];
  multiplier: number;
}

const REELSETS_WITH_UNLOCKED_ROW = ['8233931f-b1e6-4947-8efe-f50767321c34', '4d3ea44d-096e-4bc9-9933-2de9d1141307'];
const REELSETS_WITH_UNLOCKED_MULTIPLIER = [
  'c4e8a047-4829-473f-a572-a72ba73cef53',
  '4d3ea44d-096e-4bc9-9933-2de9d1141307',
];

const deduceOutcomes = (data: IBet<IDualFusionFeatures>) => {
  let currentOutcome = getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions, 0);
  const nextSymbolsIndexes = [...data.stopPositions];

  let rawWin = 0;
  const wonFreeSpins = data.paylines.some((el) => el.rewards[0]?.type === 'BONUS');
  // add empty cascade to show final state
  const lastStep = {
    winAmounts: [0],
    winPositions: [],
    multiplier: data.features.multiplier,
  };

  const steps = [...data.features.cascade, lastStep].map((cascade) => {
    currentOutcome = currentOutcome.map((reel) => reel.filter(Boolean));
    currentOutcome.forEach((reel, reelIndex) => {
      while (reel.length < config.SYMBOLS_IN_REEL) {
        nextSymbolsIndexes[reelIndex]--;
        if (nextSymbolsIndexes[reelIndex] < 0) {
          nextSymbolsIndexes[reelIndex] = data.reelset[reelIndex].length - 1;
        }
        const symbol = data.reelset[reelIndex][nextSymbolsIndexes[reelIndex]];
        reel.unshift(symbol);
      }
    });

    const outcome = currentOutcome.map((reel) => [...reel]);
    const paylines: IPayline[] = cascade.winPositions.map((winPositions, winPositionIndex) => {
      winPositions.forEach((positionIndex) => {
        const position = getPositionFromIndex(positionIndex, config.REELS_COUNT);
        currentOutcome[position.x][position.y] = '';
      });
      rawWin += cascade.winAmounts[winPositionIndex];
      return {
        winPositions,
        rewards: [
          {
            type: 'COINS',
            multiplier: cascade.winAmounts[winPositionIndex],
          },
        ],
      };
    });

    if (wonFreeSpins && cascade === lastStep) {
      const bonusPayline = data.paylines.find((el) => el.rewards[0]?.type === 'BONUS');
      if (bonusPayline) {
        data.paylines.forEach((payline) => {
          if (payline.winPositions.every((positionIndex) => bonusPayline.winPositions.includes(positionIndex)))
            paylines.push({
              ...payline,
              rewards: payline.rewards.map((reward) =>
                reward.multiplier ? { ...reward, multiplier: reward.multiplier * data.coinAmount } : reward,
              ),
            });
        });
      }
    }

    return {
      outcome,
      multiplier: cascade.multiplier,
      paylines,
    };
  });

  return { steps, rawWin };
};

export const DualFusion = ({ data }: IProps) => {
  const rowUnlocked = useMemo(() => REELSETS_WITH_UNLOCKED_ROW.includes(data.reelsetId), [data.reelsetId]);
  const multiplierUnlocked = useMemo(
    () => REELSETS_WITH_UNLOCKED_MULTIPLIER.includes(data.reelsetId),
    [data.reelsetId],
  );
  const { steps, rawWin } = useMemo(() => deduceOutcomes(data), [data, multiplierUnlocked]);
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);

  return (
    <>
      {steps.map((step, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
            marginTop: '64px',
          }}
        >
          <img src={frameImg} style={{ position: 'absolute', width: 422, height: 472, left: -17, top: -64 }} />
          <Box
            sx={{
              position: 'absolute',
              left: -5,
              top: -57,
              zIndex: -1,
              width: 133,
              height: 47,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 32,
              color: 'text.primary',
            }}
          >
            {multiplierUnlocked ? (
              'x' + step.multiplier
            ) : (
              <img style={{ width: '100%', height: '100%' }} src={multipliersLockImg} />
            )}
          </Box>

          <Outcome outcome={step.outcome} config={config} borderColor="transparent">
            {currentPayline &&
              step.paylines.includes(currentPayline) &&
              currentPayline?.winPositions.map((positionIndex) => (
                <SymbolOverlay
                  key={positionIndex}
                  position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
                  config={config}
                  sx={{ backgroundColor: 'primary.main', zIndex: -1 }}
                />
              ))}

            {!rowUnlocked &&
              [0, 1, 2, 3, 4, 5].map((index) => (
                <SymbolOverlay key={index} position={getPositionFromIndex(index, config.REELS_COUNT)} config={config}>
                  <img src={lockImg} style={{ width: '100%', height: '100%' }} />
                </SymbolOverlay>
              ))}
          </Outcome>
          <PaylinesList
            sx={{ maxWidth: 400 }}
            coinValue={data.coinValue}
            coinAmount={1}
            currency={data.currency}
            paylines={step.paylines}
            currentPayline={currentPayline}
            setCurrentPayline={setCurrentPayline}
          />
          {multiplierUnlocked && rawWin > 0 && index === steps.length - 1 && (
            <Box>
              <Typography variant="h5">
                {`${getFormattedMoney(rawWin, data.currency)} x ${data.features.multiplier} = ${getFormattedMoney(rawWin * data.features.multiplier, data.currency)}`}
              </Typography>
            </Box>
          )}
          <Box sx={{ marginTop: 1 }} />
        </Box>
      ))}
    </>
  );
};

export default DualFusion;
