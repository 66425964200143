import { useMemo } from 'react';
import { createTheme } from '@mui/material';
import { darkThemeAtom } from 'atoms';
import { useAtomValue } from 'jotai';

export const useTheme = () => {
  const darkMode = useAtomValue(darkThemeAtom);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          primary: {
            main: darkMode ? '#A1987E' : '#867C5F',
          },
        },
      }),
    [darkMode],
  );

  return theme;
};
