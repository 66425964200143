import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { IData } from 'types';
import { getFormattedMoney } from 'utils';

export interface ITitleProps {
  data: IData;
  gameName: string;
}

export const Title = ({ data, gameName }: ITitleProps) => {
  const { t } = useTranslation();

  const firstBet = data.bets[0];
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" textAlign="center" sx={{ mb: 2 }}>
        {t('betHistory')}
      </Typography>
      <Typography variant="h6" dangerouslySetInnerHTML={{ __html: t('gameName', { name: gameName }) }} />
      <Typography variant="h6" dangerouslySetInnerHTML={{ __html: t('playerId', { id: data.playerId }) }} />
      <Typography
        variant="h6"
        dangerouslySetInnerHTML={{ __html: t('cost', { cost: getFormattedMoney(data.totalBet, data.currency) }) }}
      />
      {firstBet && (
        <Typography
          variant="h6"
          dangerouslySetInnerHTML={{
            __html: t('betSize', {
              bet: getFormattedMoney(
                firstBet.coinAmount * firstBet.coinValue * firstBet.coinAmountMultiplier,
                data.currency,
              ),
            }),
          }}
        />
      )}
      <Typography
        variant="h6"
        dangerouslySetInnerHTML={{ __html: t('totalWin', { win: getFormattedMoney(data.totalWin, data.currency) }) }}
      />
    </Box>
  );
};

export default Title;
